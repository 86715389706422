import classNames from "classnames"
import Button, { ButtonV2, LinkButton } from "components/Button"
import Footer from "components/Footer"
import { Input } from "components/Input"
import Logo from "components/Logo"
import { Navbar } from "components/Navbar"
import { useUser } from "hooks/user"
import { useRouter } from "next/router"
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react"

export function Feature({ title, image, children, imageClassName }: PropsWithChildren<{ title: string, image: string; imageClassName?: string }>) {
  return <div className="group flex flex-col items-center justify-center gap-1 flex-1 relative -mt-0.5">
    <img src={image} className={classNames("group-hover:translate-y-0.5 transition-transform duration-700 h-max", imageClassName)} />
    <h2 className="text-2xl text-center text-dark-green font-semibold transition-transform group-hover:translate-y-0.5 duration-500 blur-0">{title}</h2>
    {/*TODO: icon is not centered */}
    <p className="break-words text-dark-grey text-lg mt-1 text-center opacity-0 h-1 group-hover:overflow-visible transition-opacity group-hover:opacity-100 duration-1000 group-hover:translate-0.5 w-full">
      {children}
    </p>
    {/* <p className="text-grey opacity-0 h-0 overflow-hidden transition-opacity group-hover:block group-hover:opacity-100 group-hover:h-auto duration-1000 group-hover:absolute group-hover:bottom-0 text-center"> */}
    {/* TODO: smaller screens don't like absolute bottom-0 shenanigans */}
    {/* {children} */}
    {/* </p> */}
  </div>
}

// test lol aaaa

export function FeatureSection() {
  return <div className="flex px-0 pb-3 pt-5 max-2xl:pb-7 max-2xl:pt-7 max-3xl:pb-5 max-3xl:pt-5 flex-row gap-0 justify-center">
    <Feature title="Текстовый редактор" image={'/images/main/feature-editor.png'}>
      Мощный текстовый редактор с&nbsp;функциями совместного редактирования, комментирования и&nbsp;рецензирования
    </Feature>
    {/* NOTE: this translate property is specific to the recommendations icon, due to it being centered, and having a magnifying glass, it looks off */}
    <Feature title="Контекстный поиск и&nbsp;рекомендации" image={'/images/main/feature-recommendations.png'} imageClassName="translate-x-[10px] origin-center">
      {/* <Feature title="Контекстный поиск" image={'/images/main/feature-recommendations.png'} imageClassName="translate-x-[10px] origin-center"> */}
      {/* Используйте контекстный поиск по PubMed, Mendeley и&nbsp;<a className="text-secondary" href="https://prevmed.elpub.ru/">«Терапия&nbsp;без&nbsp;границ»</a> */}
      Используйте широкие возможности инфраструктуры{' '}
      <a className="underline cursor-pointer text-secondary" href="https://prevmed.elpub.ru/" target="_blank" rel="noreferrer">
        цифровых сервисов ФГБУ&nbsp;НМИЦ&nbsp;ТПМ
      </a> и&nbsp;зарубежных ресурсов (PubMed,&nbsp;Mendeley) для управления библиографической информацией
    </Feature>
    <Feature title="Экспертная оценка" image={'/images/main/feature-review.png'}>
      Наши специалисты, включая врачей и&nbsp;ученых из&nbsp;области общественного здоровья, помогут улучшить качество Ваших работ
      {/* Получайте рекомендации и&nbsp;оценку Вашего материала от&nbsp;профессионального сообщества врачей и&nbsp;ученых */}
    </Feature>
  </div>
}
export function DemoSection() {
  const videos = [
    // "fBkztZ9Hr0k",
    // "rfCG3be-7Mk",
    // "KnGWjl1I1xY"
    // "zyJKVZpRyso",
    // "Usu6D4Gdefw",
    // "MI-QPhiGibQ"
    // "https://vk.com/video_ext.php?oid=531647934&id=456239035&hd=2&hash=7453c05d59495cfe&autoplay=1",
    // "https://vk.com/video_ext.php?oid=531647934&id=456239036&hd=2&hash=1e166123b83ab136&autoplay=1",
    // "https://vk.com/video_ext.php?oid=531647934&id=456239037&hd=2&hash=6a05b9e8b0a31ebd&autoplay=1",
    // "https://vk.com/video_ext.php?oid=531647934&id=456239038&hash=a50f9b59062591bf&autoplay=1",
    // "https://vk.com/video_ext.php?oid=531647934&id=456239040&hd=2&hash=bc0a7d4e492b143d&autoplay=1",
    // "https://vk.com/video_ext.php?oid=531647934&id=456239042&hd=2&hash=cc07b1b5d3f7ab85&autoplay=1", // Author
    // "https://vk.com/video_ext.php?oid=531647934&id=456239043&hd=2&hash=b0fac107889f3392&autoplay=1", // Author
    "https://vk.com/video_ext.php?oid=531647934&id=456239044&hd=2&hash=0f8a98e8659181db&autoplay=1", // Author (article creation)
    "https://vk.com/video_ext.php?oid=531647934&id=456239045&hd=2&hash=6d6499de0f880b18&autoplay=1", // Author (send article)
    "https://vk.com/video_ext.php?oid=531647934&id=456239039&hd=2&hash=3ae1babb34239c8c&autoplay=1" // Editor
  ]

  const router = useRouter()
  const [currentUser] = useUser()
  const [videoidx, setVideoidx] = useState(0)
  useEffect(() => {
    setVideoidx(Math.round(Math.random() * (videos.length - 1)))
  }, [])

  return <div className="flex flex-row justify-evenly py-2 min-w-fit flex-1 bg-dark-green text-white px-2">
    <div className="flex flex-col gap-2 justify-center">
      <h2 className="text-3xl max-3xl:text-2xl font-semibold text-white">Единое рабочее пространство<br />для авторов, редакторов и<br /> рецензентов</h2>
      <ButtonV2 colorscheme="default" onClick={() => {
        if (currentUser) router.push('/profile/@me')
        else router.push('/register')

      }} className="text-lg font-medium flex flex-row items-center gap-0.5 w-fit">Попробовать <img src="/images/arrow-right-full.svg" className="h-[0.8em]" /></ButtonV2> {/* TODO: action (arrow) button */}
    </div>
    <div className="relative border-white border-[5px] rounded-2xl shadow-lg"> {/* NOTE: scale-150 looks cool if on hover */}
      {/* <img src="/images/main/demo-dummy.png" className="h-auto w-30 brightness-95 rounded-lg" /> */}
      {/* <iframe */}
      {/*   className="rounded-lg" */}
      {/*   width={560*1.3} */}
      {/*   height={315*1.5} */}
      {/*   src={"https://www.youtube.com/embed/" + videos[videoidx] + "?si=ui4ku2D-SB2G6FYr&autoplay=1&loop=1&mute=1&enablejsapi=1&playlist=" + videos[videoidx]} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe> */}
      <iframe src={videos[videoidx]} width="853" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameBorder="0" allowFullScreen className="rounded-lg"></iframe>
      {/* <iframe className="h-17 w-30 rounded-lg" src="https://rutube.ru/play/embed/94ad445d5ee2c6e4851848f06ac47b7a?p=sDWN0gy-Tvultjl_FfQruA" frameBorder="0" allow="clipboard-write; autoplay" allowFullScreen></iframe> */}
      {/* <button onClick={() => alert('pretend the video is playing')} className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"><img src="/images/main/play.svg" width={24 * 5} height={24 * 5} className="backdrop-blur-md rounded-full" /></button> */}
    </div>

    {/* <div style={{ height: 394 * 1.5, width: 514 * 1.5 }} className="relative"> */}
    {/*   <button onClick={() => alert('pretend the video is playing')} className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"><img src="/images/main/play.svg" width={24 * 8} height={24 * 8} className="backdrop-blur-md rounded-full" /></button> */}
    {/*   <img src="/images/main/demo-dummy.png" className="h-full w-auto outline-white border-white border-[5px] rounded-2xl" /> */}
    {/* <div className="bg-light-grey w-4 h-4 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] rounded-full border-green border-[10px] flex justify-center items-center"> */}
    {/*   <path d="M 24 4 L 43 37 L 5 37 z" /> */}
    {/*   <div className="w-0 h-0 border-t-[25px] border-t-transparent border-l-[50px] border-l-green border-b-[25px] border-b-transparent origin-center -translate-x-[50%] rounded-xl"></div> */}
    {/* </div> */}
    {/* </div> */}
  </div >
}

export function InfoDuplet({ title, children, image }: PropsWithChildren<{ title: string, image: string | JSX.Element }>) {
  return <div className="flex flex-row justify-center items-center even:flex-row-reverse px-5 max-2xl:px-2 py-3 gap-4"> {/* xl:px-10 */}
    <div className="text-black 3xl:px-4 flex flex-col gap-[1rem] justify-center w-[50%]">
      <h2 className="text-4xl max-2xl:text-2xl font-bold text-primary">{title}</h2>
      <div className="text-2xl max-2xl:text-xl prose text-black">
        {children}
      </div>
    </div>
    {/* {typeof image == 'string' ? <div style={{ background: `url(${image})`, objectFit: 'cover' }} className="w-[50%] min-h-fit border-none outline-none"> */}
    <div className="w-[50%] bg-white p-1 3xl:p-4 flex justify-start">
      {typeof image == 'string' ? <img src={image} className="w-full h-auto rounded-xl" />
        : image}
    </div>
  </div>
}

export function HeroSection() {
  const router = useRouter()
  const [currentUser] = useUser()
  const [email, setEmail] = useState<string>('')
  // const emailRef = useRef<HTMLInputElement>(null)
  // return <div className="h-screen flex flex-col items-center justify-center text-lg" style={{ background: "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/main-page-cover.png')", backgroundSize: "cover", }}>
  return <div className="h-screen flex flex-col items-center justify-center text-lg" data-test="hero">
    <video autoPlay muted loop poster="/images/main-page-cover.png" style={{ filter: 'brightness(50%)' }} className="absolute top-0 w-full h-screen max-h-screen object-cover object-right -z-10">
      {/* TODO: credit https://coverr.co/videos/studying-Re5bMzC3dG */}
      <source src="/videos/main-page-cover.mp4" type="video/mp4" />
    </video>
    <div className="flex flex-col gap-2 xl:w-50 pt-4">
      <h1 className="font-black text-white font-[Inter] text-6xl max-2xl:text-5xl">Исследуйте, пишите,<br />публикуйте...</h1>
      <p className="text-white px-0 text-2xl max-2xl:text-xl font-medium">Первая в&nbsp;России платформа, созданная<br />
        для&nbsp;подачи, экспертизы и&nbsp;публикации<br />
        научно-методической медицинской<br />
        информации в&nbsp;одно касание</p>
      <div className="flex flex-row gap-1">
        <Input value={email}
          // onChange={(v, e) => {
          // if (e.target.reportValidity()) {
          // if (/^[^@]@[a-zA-Z_\-0-9]+\.[a-z]+$/.test(v)) {
          // setEmail(v)
          // }
          // }} 
          setValue={setEmail}
          placeholder="Ваша почта" type="email" className="w-15" autoComplete="email"
        />
        <ButtonV2 onClick={() => {
          // if (emailRef && emailRef.current?.reportValidity() && email?.length) window.location.href = 'https://forms.yandex.ru/cloud/633c34a4b10029e04c3a50d4?answer_email=' + encodeURIComponent(email)
          if (currentUser) router.push('/profile/@me')
          else router.push(email && email !== '' ? '/register?email=' + encodeURIComponent(email) : '/register')
        }}>Создать аккаунт</ButtonV2>
      </div>
    </div>
    <div className="absolute bottom-0.5 flex justify-center"><button onClick={() => window.scrollTo({ top: window.visualViewport.height, behavior: 'smooth' })}><img src="/images/main/arrow-down-white.svg" width="36" height="36" className="animate-[float_1.7s_ease_infinite] transition-opacity duration-300 ease-in-out opacity-100 hover:opacity-70" /> {/* TODO: white */}</button></div>
  </div>
}

export default function HomePage() {
  const [currentUser] = useUser()
  const router = useRouter()
  return <div>
    <Navbar variant="absolute" user={currentUser} className="bg-white" version="v2" showParentLogos /> {/* TODO: user redirect */}
    <HeroSection />
    <FeatureSection />
    <DemoSection />
    {/* <InfoDuplet title="Наша миссия" image="https://s3-alpha-sig.figma.com/img/92b9/bb16/186fc0eccca998deecddc873a7de631b?Expires=1684108800&Signature=T3wzLguiWzM-E-y1lI2gn8F8CxHyqpek098GFUhgob1xpFVIk7E~GnHwpB~c1VyfNMHsged2U4grfxxFFQROWx6d~1QTaUpFkCPr2E~7PKZ1oHqdiqvltFymw2Pw2b7H-KWHAFuq4S6C4ab4zTbq03O6vbKz-hd3dOZ29UhYymnWukjqycJ-XsU8qAMYbzvG55llvi8jm7FJraPhLqvk3TkjRM328bxMP1UFAftSXfH~2xoUcS0bRyIGE21At5F4fqtv42yprGyg-fmhL8Vzou32EEnHO8mX66RKfA3agKJFWNgNGBOsHDz8Jn2U-L86FtwLEinJyx1cSgMnBks~Fw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"> */}
    <InfoDuplet title="Наша миссия" image="/images/main/duplet-goal.png">
      <p data-test="test">Упростить и&nbsp;сделать доступным процесс публикации статей в&nbsp;ведущих рецензируемых медицинских российских&nbsp;журналах</p>
    </InfoDuplet>
    <InfoDuplet title="Удобен для&nbsp;всех — авторов, рецензентов и&nbsp;редакторов" image={"/images/main/duplet-collab.png"}>
      <ul>
        <li>Помогаем в&nbsp;работе над&nbsp;статьей от&nbsp;черновика до&nbsp;готовой публикации. Получайте рекомендации релевантных материалов для&nbsp;цитирования, советы по оформлению и&nbsp;другую полезную информацию</li>
        <li>Используйте встроенные инструменты рецензирования, управляйте шаблонами и&nbsp;работайте с&nbsp;авторским коллективом в&nbsp;реальном времени</li>
        <li>Взаимодействуйте с&nbsp;авторами и&nbsp;рецензентами в&nbsp;одном месте, просматривайте версии статьи, оставляйте комментарии, отслеживайте изменения документа в&nbsp;реальном времени</li>
      </ul>
    </InfoDuplet>
    <InfoDuplet title="Курсы, лекции, мастер-классы, программы обучения" image="/images/main/duplet-courses.png">
      <ul>
        <li>В нашей системе представлен каталог методических пособий, лекций, семинаров и&nbsp;вебинаров для&nbsp;авторов и&nbsp;рецензентов в&nbsp;очном и&nbsp;дистанционном формате</li>
        <li>Составьте&nbsp;персональную подборку обучающих программ на&nbsp;основе Ваших академических интересов и&nbsp;запишитесь на&nbsp;интересующий Вас&nbsp;курс</li>
      </ul>
      <LinkButton colorscheme="default" href="/courses">Узнать больше</LinkButton>
    </InfoDuplet>
    <div className="flex justify-center py-4 bg-light-grey text-black">
      <div className="flex flex-col gap-2">
        <h2 className="text-3xl font-semibold text-dark-grey">Всегда с&nbsp;вами. на&nbsp;каждом этапе исследования</h2>
        <ButtonV2 colorscheme="default" onClick={() => {
          if (currentUser) router.push('/profile/@me')
          else router.push('/register')
        }} className="text-lg font-medium flex flex-row items-center gap-0.5 w-fit">Попробовать <img src="/images/arrow-right-full.svg" className="h-[0.8em]" /></ButtonV2> {/* TODO: action (arrow) button */}
      </div>
    </div>
    <div className="flex flex-row justify-center gap-2 items-center h-30 object-fill" style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/images/main/cpu.png)`, backgroundSize: 'cover cover', backgroundPosition: '50% calc(50% + 24px)' }}>
      <img src="/images/main/robot.png" className="h-max w-max" />
      {/* <img src="/images/main/robot.png" width={127} height={113} /> */}
      <div className="flex flex-col gap-1 text-white max-w-xl">
        <h2 className="text-4xl">Применяйте продвинутые инструменты для&nbsp;улучшения своей публикации</h2>
        <p className="text-2xl">Сосредоточьтесь на&nbsp;своем исследовании, наши алгоритмы сделают остальное: проанализируют текст статьи и&nbsp;автоматически выделят ключевые тезисы, помогут структурировать материал, подобрать формат цитирования и&nbsp;многое другое</p>
      </div>
    </div>
    <Footer />
  </div>
}

